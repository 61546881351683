/*

 */

.viewPage {
	font-family: 'Open Sans', san-serif;
	color: #000;
	background-color: #fff;
}

.pageContent {
	margin: 40px 0px;
	min-height: 90vh;
}

a,
img {
	/* grows all links on hover */
	transition: all 0.2s ease-in-out;
}
a:hover,
img:hover {
	transform: scale(1.1);
	filter: drop-shadow(10px 10px 20px grey);
}

.row {
	--bs-gutter-x: unset !important;
}
/* mobile screens */
@media screen and (min-width: 320px) and (max-width: 767px) {
	a,
	img {
		/* grows all links on hover */
		transition: unset;
	}
	a:hover,
	img:hover {
		transform: unset;
	}
}
