.footer {
	clear: both;
	background-color: transparent;
	width: 100%;
	text-align: center;
	padding-bottom: 5px;
}

.footer a {
	color: #bd3822;
	text-decoration: none;
}

.footer a:hover {
	color: #b2a2b9;
	text-decoration: underline;
	cursor: url(../../assets/images/egg.png), pointer;
}

.separator {
	border-top: 1px dashed #bd3822;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.footer {
		padding-bottom: 15px;
	}
}
